import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const classNameContainer = 'dossier__container'
const classNameTextarea = 'dossier__textarea'

const DossierContent = ({ text, subtext, wrapperStyle }) =>
    <div className={classNameTextarea} style={wrapperStyle}>
        <h3 className="dossier__text" >{text}</h3>
        {subtext && <span className="dossier_subtext">{subtext}</span>}
        <span className="dossier__arrow-flexbox"><span className="dossier__arrow">➞</span><span className="dossier__read">Lees meer</span></span>
    </div>

const Dossier = ({ smallBackground, largeBackground, link, text, subtext, isLarge }) => {
    const largeStyle = { backgroundImage: `url('${largeBackground}')` }
    const smallStyle = { backgroundImage: `url('${smallBackground}')` }

    return (
        <div className={largeBackground && isLarge ? `${classNameContainer} ${classNameContainer}--wide` : classNameContainer}>
            <Link className="dossier__inherit dossier__link" to={link}>
                {isLarge ?
                    <div className="dossier__large-wrapper">
                        <div className="dossier__inherit dossier__large-background">
                            <DossierContent text={text} isLarge={isLarge} subtext={subtext} wrapperStyle={largeStyle} />
                        </div>
                        <div className="dossier__inherit dossier__small-background">
                            <DossierContent text={text} isLarge={isLarge} subtext={subtext} wrapperStyle={smallStyle} />
                        </div>
                    </div> :
                    <div className="dossier__inherit">
                        <DossierContent text={text} isLarge={isLarge} subtext={subtext} wrapperStyle={smallStyle} />
                    </div>
                }
            </Link>
        </div>
    )
}

Dossier.propTypes = {
    smallBackground: PropTypes.node.isRequired,
    largeBackground: PropTypes.node,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    subtext: PropTypes.string,
    isLarge: PropTypes.bool,
}

export default Dossier
