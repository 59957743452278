import * as actions from '../actions'

const initialState = {
    all: [],
    detail: {},
}

function subcategories(state = initialState, action) {
    switch (action.type) {
        case actions.FETCH_SUBCATEGORIES:
            return {
                ...state,
                all: []
            }
        case actions.FETCH_SUBCATEGORIES_SUCCESS:
            return {
                ...state,
                all: action.subcategories,
            }
        case actions.FETCH_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                detail: action.subcategory
            }
        case actions.FETCH_SUBCATEGORY:
            return {
                ...state,
                detail: {}
            }
        default:
            return state;
    }
}

export default subcategories
