import React, { useEffect, useState } from 'react'
import { Layout, Breadcrumbs, SideNavigation, SubcategoryBlocks } from '.'
import { NavLink } from 'react-router-dom'
import useCategories from '../hooks/useCategories'
import { modelTypes, generateLinks, generateBreadcrumbs, generateLink } from '../util/linkHelper'

const Sidebar = ({ category, links }) =>
    <div>
        <NavLink className="page__small-heading" to={links[modelTypes.category].link}>
            <h3>{links[modelTypes.category].title}</h3>
        </NavLink>

        {category.subcategories && <SideNavigation links={category.subcategories.map(({ id, title, active_slug }) => ({
            id,
            title,
            link: generateLink(active_slug.slug, modelTypes.subcategory, links[modelTypes.category].slug),
        }))} />}
    </div>

const CategoryContent = ({ links, breadcrumb, category }) =>
    <div className="page__parent-content">
        <div className="page__breadcrumbs">
            <Breadcrumbs links={links} />
        </div>
        <div className="page__content">
            <div className="page__side-navigation">
                {links && links[modelTypes.category] && <Sidebar category={category} links={links} breadcrumb={breadcrumb} />}
            </div>

            <div className="page__center-content">
                {links[modelTypes.category] && category.subcategories && <SubcategoryBlocks categorySlug={links[modelTypes.category].slug} subcategories={category.subcategories} />}
            </div>
        </div>
    </div>

const PageCategory = ({ match }) => {
    const { fetchCategory, detailCategory } = useCategories()
    const [breadcrumb, setBreadcrumb] = useState({})
    const [links, setLinks] = useState({})

    useEffect(() => {
        fetchCategory(match.params.category_id, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const generatedBreadcrumb = generateBreadcrumbs(modelTypes.category, detailCategory)
        const matchingParams = {
            subcategoryId: match.params.category_id,
        }

        setBreadcrumb(generatedBreadcrumb)
        setLinks(generateLinks(generatedBreadcrumb, matchingParams))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailCategory])

    return (
        <Layout>
            <CategoryContent links={links} category={detailCategory} breadcrumb={breadcrumb} />
        </Layout>
    )
}

export default PageCategory
