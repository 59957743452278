import * as actionTypes from './'
import { apiCall } from './globalActions'

export const fetchArticlesInit = () => ({
    type: actionTypes.FETCH_ARTICLES,
})

export const fetchArticlesSuccess = (articles) => ({
    type: actionTypes.FETCH_ARTICLES_SUCCESS,
    articles,
})

export const fetchArticleInit = () => ({
    type: actionTypes.FETCH_ARTICLE,
})

export const fetchArticleSuccess = (article) => ({
    type: actionTypes.FETCH_ARTICLE_SUCCESS,
    article,
})

export const fetchFeaturedArticlesInit = () => ({
    type: actionTypes.FETCH_FEATURED_ARTICLES,
})

export const fetchFeaturedArticlesSuccess = (articles) => ({
    type: actionTypes.FETCH_FEATURED_ARTICLES_SUCCESS,
    articles,
})

export const fetchArticles = () => async (dispatch) => {
    dispatch(fetchArticlesInit())
    const response = await apiCall(dispatch, '/articles')
    dispatch(fetchArticlesSuccess(response.articles))
    return Promise.resolve(response.articles)
}

export const fetchArticle = (id, isSlug = false) => async (dispatch) => {
    dispatch(fetchArticleInit())
    const response = await apiCall(dispatch, `/articles${isSlug && '/slug'}/${id}`)
    dispatch(fetchArticleSuccess(response.article))
    return Promise.resolve(response.article)
}

export const fetchFeaturedArticles = () => async (dispatch) => {
    dispatch(fetchFeaturedArticlesInit())
    const response = await apiCall(dispatch, '/home/featured')
    dispatch(fetchFeaturedArticlesSuccess(response.articles))
    return Promise.resolve(response.articles)
}
