import { useDispatch, useSelector } from 'react-redux'

import {
    fetchArticles as fetchArticlesAction,
    fetchArticle as fetchArticleAction,
    fetchFeaturedArticles as fetchFeaturedArticlesAction,
} from '../actions/articleActions'

const useArticles = () => {
    const dispatch = useDispatch()

    const allArticles = useSelector(state => state.articles.all)
    const detailArticle = useSelector(state => state.articles.detail)
    const featuredArticles = useSelector(state => state.articles.featured)

    const fetchArticles = () => dispatch(fetchArticlesAction())
    const fetchArticle = (articleId, isSlug = false) => dispatch(fetchArticleAction(articleId, isSlug))
    const fetchFeaturedArticles = () => dispatch(fetchFeaturedArticlesAction())

    return {
        allArticles,
        detailArticle,
        featuredArticles,
        fetchArticles,
        fetchArticle,
        fetchFeaturedArticles,
    }
}

export default useArticles
