import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const Tags = ({ tags, isClickable }) =>
    <div className="tags__container">
        <p className="tags__header">Tags</p>
        {tags && tags.map(({ title, id }) =>
            isClickable ?
                <Link className="tags__tag tags__tag--clickable" key={id} to={`/tags/${id}`}>{title}</Link> :
                <span key={id} className="tags__tag">{title}</span>
        )}
    </div>

Tags.defaultProps = {
    isClickable: false,
}

Tags.propTypes = {
    tags: PropTypes.array.isRequired,
    isClickable: PropTypes.bool
}

export default Tags
