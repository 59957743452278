import React from 'react'
import PropTypes from 'prop-types'
import ArrowButton from '../assets/images/ArrowButton.svg'

const SourceBlock = ({ link, text, subtext, icon }) =>
    <div className="source-block__container">
        <a className="source-block__link" rel="noopener noreferrer" target="_blank" href={link}>
            <span className="source-block__content">
                {icon && 
                    <img className="source-block__icon" alt="icon" src={icon}/>
                }
                <span className="source-block__text-area">
                    <span className="source-block__text">{text}</span>
                    {subtext && 
                        <span className="source-block__subtext">{subtext}</span>
                    }
                </span>
            </span>
            <img className="source-block__arrow" alt="icon" src={ArrowButton}/>
        </a>
    </div>

    SourceBlock.defaultProps = {
        text: "Source",
        link: "#",
    }

SourceBlock.propTypes = {
    text: PropTypes.string.isRequired,
    subtext: PropTypes.string,
    link: PropTypes.string.isRequired,
    icon: PropTypes.node,
}

export default SourceBlock
