import {useDispatch, useSelector} from 'react-redux'

import {
    handleSubmit as handleSubmitAction,
    handleCloseForm as handleCloseFormAction
} from '../actions/questionActions'

const useQuestion = () => {
    const dispatch = useDispatch();

    const currentStatus = useSelector(state => state.questions.status)

    const nameErrorMessage = useSelector(state => state.questions.errorMessages.name)
    const emailErrorMessage = useSelector(state => state.questions.errorMessages.email)
    const questionErrorMessage = useSelector(state => state.questions.errorMessages.question)

    const submitQuestion = (name, email, question) => {
        return dispatch(handleSubmitAction(name, email, question))
    }

    const closeForm = (status) => {
        return dispatch(handleCloseFormAction(status))
    }

    return {
        currentStatus,
        nameErrorMessage,
        emailErrorMessage,
        questionErrorMessage,
        closeForm,
        submitQuestion
    }
}

export default useQuestion
