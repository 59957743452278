import React from 'react'
import ReactDOM from 'react-dom'
import { ReactComponent as CrossIcon } from '../assets/images/cross-button.svg'
import usePopups from '../hooks/usePopups';

const popupRoot = document.getElementById('popup')

const CloseButton = ({ onClick }) =>
    <div className="popup__close-button">
        <CrossIcon onClick={onClick} />
    </div>

const Popup = () => {
    const { popup, dismissPopup } = usePopups()
    const { popupContent: PopupContent, title, renderCloseButton } = popup || {}

    return popup ? ReactDOM.createPortal(
        <div className="popup__wrapper">
            <div className="popup__background" onClick={dismissPopup} />
            <div className="popup__container">
                {title && <h3 className="popup__header">{title}</h3>}
                {renderCloseButton && <CloseButton onClick={dismissPopup} />}
                <PopupContent />
            </div>
        </div>,
        popupRoot
    ) : null
}

export default Popup