import * as actionTypes from './'
import { apiCall } from './globalActions'

export const fetchDossiersSuccess = (dossiers) => ({
    type: actionTypes.FETCH_DOSSIERS_SUCCESS,
    dossiers
})

export const fetchDossiersInit = (dossiers) => ({
    type: actionTypes.FETCH_DOSSIERS,
    dossiers
})

export const fetchDossiers = () => async (dispatch) => {
    dispatch(fetchDossiersInit())
    const response = await apiCall(dispatch, '/articles/dossiers')
    dispatch(fetchDossiersSuccess(response.dossiers))
    return Promise.resolve(response.dossiers)
}
