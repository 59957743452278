import React from 'react'
import { PageHome, PageCategories, PageArticle, PageCategory, PageSubcategory, Popup, PageDossiers, ScrollWrapper } from './components'
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'

const App = () => (
    <div className="app__container">
        <Popup />
        <BrowserRouter>
            <ScrollWrapper>
                <Switch>
                    <Route path="/home" component={PageHome} />

                    <Route exact path="/themas" component={PageCategories} />
                    <Route exact path="/themas/:category_id" component={PageCategory} />
                    <Route exact path="/onderwerpen/:subcategory_id" component={PageSubcategory} />
                    <Route exact path="/vragen/:article_id" component={PageArticle} />
                    <Route exact path="/dossiers" component={PageDossiers} />

                    <Route path={['/', '/*']} render={() => <Redirect to="/home" />} />
                </Switch>
            </ScrollWrapper>
        </BrowserRouter>
    </div>
)

export default App