import React from 'react'
import { Layout, Breadcrumbs, DossierWrapper } from '.'

const PageDossiers = () => (
    <Layout>
        <div className="page__gray-area">
            <div className="page-categories__breadcrumbs">
                <Breadcrumbs links={{ active: {
                    title: 'Dossiers',
                    link: '/dossiers',
                    slug: 'dossiers'
                } }} />
            </div>

            <h4 className="page__main-heading page__main-heading--small-padding">Dossiers</h4>

            <DossierWrapper renderMoreButton={false} />
        </div>
    </Layout>

)


export default PageDossiers