import React from 'react'
import { Link } from 'react-router-dom'
import { QuestionLink, MissingArticleMessage } from '.'
import { generateLink, modelTypes } from '../util/linkHelper'

const SubcategoryBlocks = ({ subcategories, categorySlug }) =>
    <div className='subcategory-blocks__container'>
        {subcategories.map(({ id, title, articles, active_slug: activeSubcategorySlug }) =>
            <div key={id} className="page__content-block">
                <h4 className="page__medium-heading">{title}</h4>
                {articles && articles.slice(0, 5).map(({ title, id, active_slug: activeArticleSlug }) =>
                    <div key={id} className="subcategory-blocks__link">
                        <QuestionLink key={id} question={title} link={generateLink(activeArticleSlug.slug, modelTypes.article, categorySlug, activeSubcategorySlug.slug)} />
                    </div>
                )}
                {articles.length > 5 && <div className="subcategory-blocks__button">
                    <Link
                        to={generateLink(activeSubcategorySlug.slug, modelTypes.subcategory, categorySlug)}
                        className="button__container button__container--tertiary-radius button__container--quartary-state"
                    >
                        bekijk alle {articles.length} vragen
                    </Link>
                </div>}
                {articles.length === 0 &&
                    <MissingArticleMessage/>
                }
            </div>
        )}
    </div>

export default SubcategoryBlocks
