import { useDispatch, useSelector } from 'react-redux'
import {
    openMenu as openMenuAction,
    closeMenu as closeMenuAction
} from '../actions/globalActions'

const useMenu = () => {
    const dispatch = useDispatch()

    const isMenuOpen = useSelector(state => state.global.isMenuOpen)

    const openMenu = () => dispatch(openMenuAction())
    const closeMenu = () => dispatch(closeMenuAction())

    return {
        isMenuOpen,
        openMenu,
        closeMenu
    }
}

export default useMenu