import React, {useState, useEffect} from 'react'
import {Button} from '../components'
import useQuestion from "../hooks/useQuestions"
import successImage from '../assets/images/form-succes-image.svg'

const classNameTextfield = 'user-input-form__textfield'
const classNameTextfieldError = 'user-input-form__textfield user-input-form__textfield--error'
const classNameTextarea = 'user-input-form__large-textfield'
const classNameTextareaError = 'user-input-form__large-textfield user-input-form__large-textfield--error'

const UserInputForm = () => {

    const [nameValue, setNameValue] = useState('')
    const [emailValue, setEmailValue] = useState('')
    const [questionValue, setQuestionValue] = useState('')

    const {currentStatus, nameErrorMessage, emailErrorMessage, questionErrorMessage, closeForm,submitQuestion} = useQuestion()

    const hasError = (errorMessage) => !!errorMessage

    function submit(event) {
        event.preventDefault()
        submitQuestion(nameValue, emailValue, questionValue)
    }

    useEffect( () => {
        closeForm(currentStatus)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ErrorMessage = ({errorMessage}) => {
        if (errorMessage !== '' || errorMessage !== undefined) {
            return <label className="user-input-form__error-message-label">{errorMessage}</label>
        }
    }

    return (
        <div className="user-input-form__container">
            {(currentStatus === '' || currentStatus === 'failed') &&
            <form className="user-input-form__form" onSubmit={submit}>
                <div className="user-input-form__form-inputs">
                    <label className="user-input-form__input-label">Naam</label>
                    <input
                        name="name"
                        value={nameValue}
                        className={hasError(nameErrorMessage) ? classNameTextfieldError : classNameTextfield}
                        type="text"
                        onChange={e => setNameValue(e.target.value)}
                        maxLength="40"
                    />
                    {currentStatus === 'failed' && hasError(nameErrorMessage) && <ErrorMessage errorMessage={nameErrorMessage}/>}

                    <label className="user-input-form__input-label">E-mail adres</label>
                    <input
                        name="name"
                        value={emailValue}
                        className={hasError(emailErrorMessage) ? classNameTextfieldError : classNameTextfield}
                        type="text"
                        onChange={e => setEmailValue(e.target.value)}
                        maxLength="40"
                    />
                    {currentStatus === 'failed' && hasError(emailErrorMessage) && <ErrorMessage errorMessage={emailErrorMessage}/>}
                </div>
                <hr className="user-input-form__line"/>
                <div className="user-input-form__form-textarea">
                    <label className="user-input-form__textarea-label">Je vraag</label>
                    <textarea
                        name="question"
                        value={questionValue}
                        className={hasError(questionErrorMessage) ? classNameTextareaError : classNameTextarea}
                        type="text"
                        placeholder="Omschrijf je vraag"
                        onChange={e => setQuestionValue(e.target.value)}
                    />
                    {currentStatus === 'failed' && hasError(questionErrorMessage) && <ErrorMessage errorMessage={questionErrorMessage}/>}
                </div>
                <div className="user-input-form__button">
                    <Button text="Versturen" state="quartary" radius="tertiary" submit/>
                </div>
            </form>}
            {currentStatus === 'success' &&
                <div className="form-success-state__container">
                    <img
                        className="form-success-state__image"
                        src={successImage}
                        alt="form Success"
                    />
                    <label className="form-success-state__message-label">
                        Bedankt voor het insturen van je vraag. We gaan het uitzoeken en het antwoord mogelijk aan de Bieb
                        toevoegen.
                    </label>
                </div>
            }
        </div>
    )
}

export default UserInputForm
