import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import HomeIcon from '../assets/images/HomeIcon.svg'

const Crumb = ({ model: { link, title } }) =>
    <div key={link} className="breadcrumbs__link-container">
        <Link to={link} className="breadcrumbs__link">{title}</Link>
    </div>

const BreadCrumbs = ({ links }) =>
    <div className="breadcrumbs__container">
        <div className="breadcrumbs__icon-container">
            <Link to="/" className="breadcrumbs__icon-link">
                <img className="breadcrumbs__icon-image" src={HomeIcon} alt="icon" />
            </Link>
        </div>
        <div className="breadcrumbs__list">
            {Object.values(links).map(link => link && <Crumb key={link.slug} model={link} />)}
        </div>
    </div>

BreadCrumbs.propTypes = {
    links: PropTypes.objectOf(PropTypes.exact({
        link: PropTypes.string,
        title: PropTypes.string,
        slug: PropTypes.string
    })).isRequired
}

export default BreadCrumbs