import React from 'react'
import PropTypes from 'prop-types'

const Footer = ({image, links}) =>
    <div className="footer__container">
        <div className="footer__content">
            {image && <img className="footer__image" src={image} alt="logo" />}
            <div className="footer__links">
                {links && Object.entries(links).map(([key, value]) => <a key={key} rel="noopener noreferrer" target="_blank" className="footer__link" href={value}>{key}</a>)}
            </div>
        </div>
    </div>

Footer.propTypes = {
    image: PropTypes.node,
    links: PropTypes.object,
}

export default Footer
