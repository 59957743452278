import React, { useEffect } from 'react'
import { QuestionLink } from '.'
import useArticles from '../hooks/useArticles'
import { generateLink, modelTypes } from '../util/linkHelper'

const ClassNameQuestions = 'question-link-wrapper__questions'
const QuestionLinkWrapper = ({ hasColumns }) => {
    const { fetchFeaturedArticles, featuredArticles } = useArticles()

    useEffect(() => {
        fetchFeaturedArticles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={hasColumns ? `${ClassNameQuestions} ${ClassNameQuestions}--column` : ClassNameQuestions}>
            {featuredArticles.map(({ title, active_slug, id }) =>
                <div key={id} className="question-link-wrapper__question">
                    <QuestionLink question={title} link={generateLink(active_slug.slug, modelTypes.article)} />
                </div>
            )}
        </div>
    )
}

export default QuestionLinkWrapper
