import { combineReducers } from 'redux'
import categories from './categories'
import subcategories from './subcategories'
import global from './global'
import dossiers from './dossiers'
import links from './links'
import articles from './articles'
import questions from './questions'

const rootReducer = combineReducers({
    global,
    categories,
    subcategories,
    dossiers,
    links,
    articles,
    questions
})

export default rootReducer
