import React from 'react'
import noArticlesImage from '../assets/images/no-articles-image.svg'

const MissingArticleMessage = () =>
    <div className="missing-article-message__container">
        <img src={noArticlesImage} className="missing-article-message__image" alt="No articles"/>
        <label className="missing-article-message__message">Nog geen artikelen</label>
    </div>


export default MissingArticleMessage
