import React from 'react'
import PropTypes from 'prop-types'
import { Button, UserInputForm } from '../components'
import usePopups from '../hooks/usePopups';

const CtaBlock = ({ text }) => {
    const { openPopup } = usePopups();

    const onClick = () => {
        const PopupContent = () => <UserInputForm />
        openPopup(PopupContent, 'Vraag Insturen')
    }

    return (
        <div className="cta-block__container">
            <h4 className="cta-block__text">{text}</h4>
            <div className="cta-block__button">
                <Button link="/" text="Vraag Insturen" radius="tertiary" state="secondary" onClick={onClick} />
            </div>
        </div>
    )
}

CtaBlock.propTypes = {
    text: PropTypes.string,
    buttonLink: PropTypes.string,
    buttonClick: PropTypes.func,
}

export default CtaBlock
