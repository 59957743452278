import { getBaseImageUrl } from './environmentHelper'

/**
 * Retrieve the image url form a (twill) medias object
 * Uses `medias[0].uuid` if one exists.
 * Returns the api baseUrl appended by the retrieved uuid.
 * If medias is undefined or empty, returns null or fallback if defined.
 * 
 * @param {object} medias The medias object as returned by the (twill) API
 * @param {any} fallback
 */
const mediaUrl = (media, fallback = null) => {
    if (media) {
        return `${getBaseImageUrl()}/${media.uuid}`
    }
    return fallback;
}

/**
 * Returns the media which role equals the supplied role.
 * 
 * @param {Array} medias A list of medias
 * @param {string} role The role string to search the list of medias for
 */
const getMediaByRole = (medias, role) => {
    if (medias && medias.length > 0) {
        return medias.find((media) => media.pivot.role === role)
    }

    return null;
}

export {
    mediaUrl,
    getMediaByRole
}
