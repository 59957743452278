import * as actions from '../actions'

const initialState = {
    all: [],
    detail: {},
}

function categories(state = initialState, action) {
    switch (action.type) {
        case actions.FETCH_CATEGORIES:
            return {
                ...state,
                detail: {}
            }
        case actions.FETCH_CATEGORIES_SUCCESS:
            return {
                ...state,
                all: action.categories,
            }
        case actions.FETCH_CATEGORY_SUCCESS:
            return {
                ...state,
                detail: action.category
            }
        case actions.FETCH_CATEGORY:
            return {
                ...state,
                detail: {}
            }
        default:
            return state;
    }
}

export default categories
