import React from 'react'
import PropTypes from 'prop-types'

const baseClass = 'button__container'

var styleRadius = ['primary', 'secondary', 'tertiary']
var styleState = ['primary', 'secondary', 'tertiary', 'quartary', 'quinary']

const Button = ({ text, radius, state, onClick }) => {
    let className = `${baseClass} ${baseClass}--${radius}-radius ${baseClass}--${state}-state`

    return (
        <button className={className} onClick={onClick}>
            {text}
        </button>
    )
}

Button.defaultProps = {
    state: 'primary',
}

Button.propTypes = {
    onClick: PropTypes.func,
    submit: PropTypes.bool,
    text: PropTypes.string.isRequired,
    radius: PropTypes.oneOf(styleRadius),
    state: PropTypes.oneOf(styleState),
}

export default Button