import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import LogoHeader from '../assets/images/LogoHeader.svg'
import blue_magnifying_glass from '../assets/images/blue-magnifying-glass.svg'
import menu from '../assets/images/menu.svg'
import { Link } from 'react-router-dom'
import { Searchbar } from '.'
import useMenu from '../hooks/useMenu'

const StickyHeader = ({ imageLink, isLarge }) => {
    const [showStickyHeader, setStickyHeaderVisibility] = useState(false)
    const { openMenu } = useMenu()
    const size = isLarge ? 450 : 240

    useEffect(() => {
        const scrollWrapperNode = document.getElementById("scroll-wrapper")
        scrollWrapperNode && scrollWrapperNode.addEventListener('scroll', changeClass)

        return () => scrollWrapperNode && scrollWrapperNode.removeEventListener('scroll', changeClass)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const changeClass = () => {
        let yValue = document.getElementById("scroll-wrapper").scrollTop
        setStickyHeaderVisibility(yValue > size)
    }

    return (
        <div className={`sticky-header__container ${showStickyHeader && 'sticky-header__container--shown'}`}>
            <div className="sticky-header__content">
                <div className="sticky-header__logo">
                    <Link to={imageLink}>
                        <img src={LogoHeader} className="sticky-header__logo" alt="logo" />
                    </Link>
                </div>
                <div className="sticky-header__searchbar">
                    <Searchbar decoration={blue_magnifying_glass} placeholder="Omschrijf je vraag ..." />
                </div>
                <div className="sticky-header__nav-button" onClick={openMenu}>
                    <img src={menu} alt="menu" />
                </div>
            </div>
        </div>
    )
}

StickyHeader.propTypes = {
    imageLink: PropTypes.string,
}

export default StickyHeader