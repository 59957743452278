import * as actions from '../actions'

const initialState = {
    all: [],
    detail: {},
    featured: [],
}

function articles(state = initialState, action) {
    switch (action.type) {
        case actions.FETCH_ARTICLES:
            return {
                ...state,
                all: {},
            }
        case actions.FETCH_ARTICLES_SUCCESS:
            return {
                ...state,
                all: action.articles
            }
        case actions.FETCH_ARTICLE:
            return {
                ...state,
                detail: {}
            }
        case actions.FETCH_ARTICLE_SUCCESS:
            return {
                ...state,
                detail: action.article
            }
        case actions.FETCH_FEATURED_ARTICLES:
            return {
                ...state,
                featured: []
            }
        case actions.FETCH_FEATURED_ARTICLES_SUCCESS:
            return {
                ...state,
                featured: action.articles
            }
        default:
            return state;
    }
}

export default articles
