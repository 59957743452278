import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const Categorisation = ({ categories, subcategories }) =>
    <div className="categorisation__container">
        <p className="categorisation__heading">Thema</p>
        <div className="categorisation__subcontainer">
            {categories.map(({title, link}) => 
                <Link key={title} to={link} className="categorisation__link">{title}</Link>
            )}
        </div>
        <p className="categorisation__heading">Onderwerp</p>
        <div className="categorisation__subcontainer">
            {subcategories.map(({title, link}) => 
                <Link key={title} to={link} className="categorisation__link">{title}</Link>
            )}
        </div>
    </div>

Categorisation.propTypes = {
    categories: PropTypes.array,
    subcategories: PropTypes.array
}

export default Categorisation
