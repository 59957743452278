import React from 'react'
import PropTypes from 'prop-types'

const ArticleImage = ({ url, alt }) =>
    <div className="article-image__container">
        <img src={url} alt={alt} className="article-image__content" />
    </div>

ArticleImage.propTypes = {
    url: PropTypes.string.isRequired,
    alt: PropTypes.string
}

export default ArticleImage
