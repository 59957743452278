import * as actionTypes from './'
import { apiCall } from './globalActions'

export const fetchCategoriesInit = () => ({
    type: actionTypes.FETCH_CATEGORIES
})

export const fetchCategoriesSuccess = (categories) => ({
    type: actionTypes.FETCH_CATEGORIES_SUCCESS,
    categories,
})
export const fetchCategoryInit = () => ({
    type: actionTypes.FETCH_CATEGORY
})

export const fetchCategorySuccess = (category) => ({
    type: actionTypes.FETCH_CATEGORY_SUCCESS,
    category,
})

export const fetchCategories = () => async (dispatch) => {
    dispatch(fetchCategoriesInit())
    const response = await apiCall(dispatch, '/categories')
    dispatch(fetchCategoriesSuccess(response.categories))
    return Promise.resolve(response.categories)
}

export const fetchCategory = (id, isSlug = false) => async (dispatch) =>  {
    dispatch(fetchCategoryInit())
    const response = await apiCall(dispatch, `/categories${isSlug && '/slug'}/${id}`)
    dispatch(fetchCategorySuccess(response.category))
    return Promise.resolve(response.category)
}
