import * as actions from '../actions'

const initialState = {
    errorMessages: {},
    status: ''
}

function questions(state = initialState, action) {
    switch (action.type) {
        case actions.POST_QUESTION_SUCCESS:
            return {
                ...state,
                errorMessages: {},
                status: action.status
            }
        case actions.POST_QUESTION_FAILURE:
            return {
                ...state,
                errorMessages: {
                    name: action.errorMessages.name,
                    email: action.errorMessages.email,
                    question: action.errorMessages.question
                },
                status: action.status
            }
        case actions.POST_QUESTION_RESET:
            return {
                ...state,
                errorMessages: initialState.errorMessages,
                status: initialState.status
            }
        default:
            return state;
    }
}
export  default questions
