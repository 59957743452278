import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import useMenu from '../hooks/useMenu'

import logo from '../assets/images/LogoHeader_inverse.svg'
import cancelIcon from '../assets/images/Cancel.svg'
import Button from './Button';

const Menu = ({ imageLink, menuItems, onClick }) => {
    const { closeMenu } = useMenu()

    return (
        <div className="menu__container">
            <div className="menu__header-wrapper">
                <div className="menu__header-logo">
                    <NavLink to={imageLink}>
                        <img src={logo} alt="Plinkr bieb logo" />
                    </NavLink>
                </div>
                <div onClick={closeMenu} className="menu__header-icon">
                    <img src={cancelIcon} alt="close" />
                </div>
            </div>

            <div className="menu__body">
                <ul className="menu__nav-items">
                    {menuItems.map(({ icon, url, value }) => (
                        <NavLink to={url} key={value} className="menu__nav-link-container" activeClassName="menu__nav-link-container--active">
                            <li className="menu__nav-link">
                                <div className="menu__nav-icon">{icon}</div>
                                <span className="menu__nav-title">{value}</span>
                            </li>
                        </NavLink>
                    ))}
                </ul>
            </div>

            <div className="menu__footer-wrapper">
                <div className="menu__footer-button">
                    <Button text="Vraag insturen" radius="tertiary" state="secondary" onClick={onClick} />
                </div>
            </div>
        </div>
    )
}


Menu.propTypes = {
    imageLink: PropTypes.string,
}

export default Menu
