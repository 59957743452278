import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const classNameLink = 'side-navigation__link'
const classNameList = 'side-navigation__list'
const SideNavigation = ({ links, singleColumn }) =>
    <ul className="side-navigation__container">
        {links.map(({ id, title, link, isActive }) =>
            <li className={`${isActive ? `${classNameList} ${classNameList}--active` : `${classNameList} ${classNameList}--inactive`} ${singleColumn && `${classNameList}--single-column`}`} key={id}>
                <Link className={isActive ? `${classNameLink} ${classNameLink}--active` : `${classNameLink} ${classNameLink}--inactive`} to={link}>
                    {title}
                </Link>
            </li>
        )}
    </ul>

SideNavigation.propTypes = {
    links: PropTypes.array.isRequired,
}

export default SideNavigation
