import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import grayMagnifyingGlass from '../assets/images/gray-magnifying-glass.svg'
import { ReactComponent as CrossButton } from '../assets/images/cross-button.svg'
import useSearch from '../hooks/useSearch'
import { generateLink, modelTypes } from '../util/linkHelper'
import illustration_search from '../assets/images/illustration_search.svg'

const classNameContainer = 'searchbar__input-container'
const classNameSubContainer = 'searchbar__input-subcontainer'
const classNameInput = 'searchbar__inputbox'

const ArticleResult = ({ articles }) =>
    <div className="searchbar__dropdown-results">
        {articles.slice(0, 5).map(({ id, active_slug, title }) =>
            <div className="searchbar__dropdown-result" key={id}>
                <Link
                    className="searchbar__dropdown-result-link"
                    to={generateLink(active_slug.slug, modelTypes.article)}
                >
                    {title}
                </Link>
            </div>
        )}
    </div>

const TagsResult = ({ tags, modelType }) =>
    <div>
        {tags.slice(0, 5).map(({ id, active_slug, title }) =>
            <div className="searchbar__dropdown-tag" key={id}>
                <img
                    src={grayMagnifyingGlass}
                    className="searchbar__dropdown-tag-icon"
                    alt="search"
                />

                <Link
                    className="searchbar__dropdown-tag-link"
                    to={generateLink(active_slug.slug, modelType)}
                >
                    {title}
                </Link>
            </div>
        )}
    </div>

const SearchResult = ({ result }) =>
    <div>
        {result.articles.length > 0 && <ArticleResult articles={result.articles} />}

        {(result.categories.length > 0 || result.subcategories.length > 0) &&
            <div className="searchbar__dropdown-tags">
                {result.categories.length > 0 && <TagsResult tags={result.categories} modelType={modelTypes.category} />}
                {result.subcategories.length > 0 && <TagsResult tags={result.subcategories} modelType={modelTypes.subcategory} />}
            </div>
        }
    </div>

const Searchbar = ({ placeholder, decoration, isReversed }) => {
    const [searchbarValue, setSearchbarValue] = useState('')
    const [searchbarFocus, setSearchbarFocus] = useState(false)
    const [searchTimeout, setSearchTimeout] = useState(null)
    const { searchResult, isSearching, fetchSearch, clearResults } = useSearch()
    const hasSearchValue = searchbarValue.length > 0

    const onChange = (value) => {
        clearResults()
        setSearchbarValue(value)
        setSearchTimeout(clearTimeout(searchTimeout))

        if (value && value.length > 1) {
            setSearchTimeout(setTimeout(() => {
                fetchSearch(value)
                setSearchTimeout(clearTimeout(searchTimeout))
            }, 500))
        }
    }

    /**
     * ComponentDidUnmount
     */
    useEffect(() => () => {
        clearTimeout(searchTimeout)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onClear = () => {
        setSearchbarValue('')
        clearResults()
        handleFocus(false)
    }

    const handleFocus = (request) => {
        const shouldFocus = searchbarValue ? true : request
        setSearchbarFocus(shouldFocus)
    }

    return (
        <div className="searchbar__container">
            <div className={`${hasSearchValue ? `${classNameContainer} ${classNameContainer}--open` : classNameContainer} ${searchbarFocus ? `${classNameContainer}--focused` : ''}`}>
                <div className={isReversed ? `${classNameSubContainer} ${classNameSubContainer}--reverse` : classNameSubContainer}>
                    <input
                        value={searchbarValue}
                        className={hasSearchValue ? `${classNameInput} ${classNameInput}--open` : `${classNameInput} ${classNameInput}--closed`}
                        type="text"
                        style={{backgroundImage: `url('${decoration}')`}}
                        onChange={e => onChange(e.target.value)}
                        placeholder={placeholder}
                        onFocus={() => handleFocus(true)}
                        onBlur={() => handleFocus(false)}
                    />        
                </div>
            </div>

            {isSearching && <div className="searchbar__loader"/> }
            {!isSearching && hasSearchValue && <div className="searchbar__cross-button-wrapper"><CrossButton onClick={onClear} /></div> }

            {hasSearchValue &&
            <div className="searchbar__dropdown-container">
                {searchResult.articles.length > 0 || searchResult.tags.length > 0 ?
                    <SearchResult result={searchResult}/> :
                    <div>
                        {!isSearching && searchbarValue.length > 1 && !searchTimeout &&
                        <div className="searchbar__dropdown-no-results">
                            <img src={illustration_search} className="searchbar__dropdown-no-results-image" alt="No results"/>
                            <p className="searchbar__dropdown-no-results-label">Geen resultaten gevonden.</p>
                        </div>
                        }
                    </div>
                }
            </div>
            }
        </div>
    )
}

Searchbar.propTypes = {
    placeholder: PropTypes.string,
    decoration: PropTypes.node,
    tags: PropTypes.array,
    results: PropTypes.array,
    isReversed: PropTypes.bool,
}

export default Searchbar
