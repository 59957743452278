import React from 'react'
import { Layout, Breadcrumbs, CategoryButtonWrapper } from '.'

const PageCategories = () => (
    <Layout>
        <div className="page__gray-area">
            <div className="page-categories__breadcrumbs">
                <Breadcrumbs links={{ active: {
                    title: 'Thema\'s',
                    link: '/themas',
                    slug: 'themas'
                } }} />
            </div>

            <h4 className="page__main-heading page__main-heading--small-padding">Thema's</h4>

            <CategoryButtonWrapper />
        </div>
    </Layout>

)


export default PageCategories