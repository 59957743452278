import * as actions from '../actions'

const initialState = {
    popup: null,
    isLoading: false,
    isSearching: false,
    isMenuOpen: false,
    searchResult: {
        articles: [],
        tags: [],
        categories: [],
        subcategories: []
    }
}

function global(state = initialState, action) {
    switch(action.type) {
        case actions.REQUEST_START:
            return {
                ...state,
                isLoading: true,
            }
        case actions.REQUEST_FINISH:
            return {
                ...state,
                isLoading: false,
            }
        case actions.POPUP_OPEN:
            return {
                ...state,
                popup: action.popup,
            }
        case actions.POPUP_DISMISS:
            return {
                ...state,
                popup: '',
            }
        case actions.MENU_OPEN:
            return {
                ...state,
                isMenuOpen: true,
            }
        case actions.MENU_CLOSE:
            return {
                ...state,
                isMenuOpen: false,
            }
        case actions.FETCH_SEARCH:
            return {
                ...state,
                isSearching: true,
            }
        case actions.FETCH_SEARCH_SUCCESS:
            return {
                ...state,
                isSearching: false,
                searchResult: action.searchResult
            }
        case actions.CLEAR_SEARCH:
            return {
                ...state,
                searchResult: initialState.searchResult
            }
        default:
            return state;
    }
}

export default global
