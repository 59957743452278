import * as actionTypes from './'
import {apiCall} from "./globalActions";

const handleSubmitInit = () => ({
    type: actionTypes.POST_QUESTION
})

export const handleSubmitSuccess = (status) => {
    return {
        type: actionTypes.POST_QUESTION_SUCCESS,
        status: status,
    }
}

export const handleSubmitFailure = (status, nameMessage, emailMessage, questionMessage) => {
    return {
        type: actionTypes.POST_QUESTION_FAILURE,
        status: status,
        errorMessages: {
            name: nameMessage,
            email: emailMessage,
            question: questionMessage
        }
    }
}

export const handleCloseForm = () => {
    return {
        type: actionTypes.POST_QUESTION_RESET
    }
}

export const handleSubmit = (name, email, question) => async (dispatch) => {

    dispatch(handleSubmitInit());

    var response = await apiCall(dispatch, '/questions', 'POST', {name, email, question})
    if (response.status === 'success') {
        return dispatch(handleSubmitSuccess(response.status))
    }

    return dispatch(handleSubmitFailure(response.status, response.errorMessages.name, response.errorMessages.email,
        response.errorMessages.question))
}
