import { useDispatch, useSelector } from 'react-redux'
import {
    openPopup as openPopupAction,
    dismissPopup as dismissPopupAction
} from '../actions/globalActions'

const usePopups = () => {
    const dispatch = useDispatch()

    const popup = useSelector(state => state.global.popup)
    const hasPopup = !!popup

    const openPopup = (PopupContent, title, renderCloseButton) => dispatch(openPopupAction(PopupContent, title, renderCloseButton))
    const dismissPopup = () => dispatch(dismissPopupAction())

    return {
        popup,
        hasPopup,
        openPopup,
        dismissPopup
    }
}

export default usePopups