import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const QuestionLink = ({ question, subinfo, link, sublink }) =>
    <div className="question-link__container">
        <Link className="question-link__link" to={link}>
            <h3 className="question-link__question">{question}</h3>
        </Link>
        {subinfo && 
            <Link className="question-link__sublink" to={sublink}>
                <span className="question-link__subinfo">{subinfo}</span>
            </Link>
        }
    </div>

QuestionLink.propTypes = {
    question: PropTypes.string.isRequired,
    subinfo: PropTypes.string,
    link: PropTypes.string.isRequired,
    sublink: PropTypes.string,
}

export default QuestionLink
