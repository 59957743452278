import * as actionTypes from './'
import { apiCall} from './globalActions'

export const fetchSubcategoriesInit = () => ({
    type: actionTypes.FETCH_SUBCATEGORIES,
})

export const fetchSubcategoriesSuccess = (subcategories) => ({
    type: actionTypes.FETCH_SUBCATEGORIES_SUCCESS,
    subcategories,
})

export const fetchSubcategoryInit = () => ({
    type: actionTypes.FETCH_SUBCATEGORY,
})

export const fetchSubcategorySuccess = (subcategory) => ({
    type: actionTypes.FETCH_SUBCATEGORY_SUCCESS,
    subcategory,
})

export const fetchSubcategories = () => async (dispatch) => {
    dispatch(fetchSubcategoriesInit())
    const response = await apiCall(dispatch, '/subcategories')
    dispatch(fetchSubcategoriesSuccess(response.subcategories))
    return Promise.resolve(response.subcategories)
}

export const fetchSubcategory = (id, isSlug = false) => async (dispatch) =>  {
    dispatch(fetchSubcategoryInit())
    const response = await apiCall(dispatch, `/subcategories${isSlug && '/slug'}/${id}`)
    dispatch(fetchSubcategorySuccess(response.subcategory))
    return Promise.resolve(response.subcategory)
}
