import React from 'react'
import { Layout, CategoryButtonWrapper, QuestionLinkWrapper, DossierWrapper } from '../components'

const HomePage = () =>
    <Layout headerIsLarge>
        <div className="page__white-area">
            <h4 className="page__main-heading">Veelgestelde vragen</h4>
            <QuestionLinkWrapper hasColumns />
        </div>

        <div className="page__gray-area">
            <h4 className="page__main-heading">Thema's</h4>
            <CategoryButtonWrapper canShrink />
        </div>

        <div className="page__white-area">
            <h4 className="page__main-heading">Dossiers</h4>
            <DossierWrapper />
        </div>
    </Layout>

export default HomePage
