import * as actionTypes from './'
import { getBaseApiUrl } from '../util/environmentHelper'

const requestHeaders = {
    'Content-Type': 'application/json'
}
const baseUrl = `${getBaseApiUrl()}/api`

export const requestStart = () => ({
    type: actionTypes.REQUEST_START
})

export const requestFinish = () => ({
    type: actionTypes.REQUEST_FINISH
})

const responseStatus = (call) => {
    if (call.status === 422) {
        console.log(call.status + " status")
    } else if (call.status !== 200) {
        throw Error(
            JSON.stringify({response: call.statusText, status: call.status}),
        )
    }
}

export const apiCall = async (dispatch, url, method, body) => {
    dispatch(requestStart())
    const call = await fetch(`${baseUrl}${url}`, {
        method,
        headers: body ? requestHeaders : {},
        body: JSON.stringify(body)
    })

    responseStatus(call)
    dispatch(requestFinish())

    const response = await call.json();
    return response;
}

export const openPopup = (popupContent, title = '', renderCloseButton = true) => ({
    popup: {
        popupContent,
        title,
        renderCloseButton,
    },
    type: actionTypes.POPUP_OPEN
})

export const dismissPopup = () => ({
    type: actionTypes.POPUP_DISMISS
})

export const openMenu = () => ({
    type: actionTypes.MENU_OPEN
})

export const closeMenu = () => ({
    type: actionTypes.MENU_CLOSE
})

const fetchSearchInit = () => ({
    type: actionTypes.FETCH_SEARCH
})

const fetchSearchSucces = (articles, tags, categories, subcategories) => ({
    type: actionTypes.FETCH_SEARCH_SUCCESS,
    searchResult: {
        articles,
        tags,
        categories,
        subcategories
    }
})

export const fetchSearch = searchQuery => async (dispatch) => {
    dispatch(fetchSearchInit())
    const response = await apiCall(dispatch, '/articles/search', 'POST', {searchQuery})

    return dispatch(fetchSearchSucces(response.articles, response.tags, response.categories, response.subcategories))
}

export const clearSearch = () => ({
    type: actionTypes.CLEAR_SEARCH
})
