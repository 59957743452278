import { useDispatch, useSelector } from 'react-redux'

import {
    fetchSubcategories as fetchSubcategoriesAction,
    fetchSubcategory as fetchSubcategoryAction,
} from '../actions/subcategoryActions'

const useSubcategories = () => {
    const dispatch = useDispatch()

    const allSubcategories = useSelector(state => state.subcategories.all)
    const detailSubcategory = useSelector(state => state.subcategories.detail)

    const fetchSubcategories = () => dispatch(fetchSubcategoriesAction())
    const fetchSubcategory = (subcategoryId, isSlug = false) => dispatch(fetchSubcategoryAction(subcategoryId, isSlug))

    return {
        fetchSubcategories,
        fetchSubcategory,
        allSubcategories,
        detailSubcategory
    }
}

export default useSubcategories
