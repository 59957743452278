import React, { useEffect, useState } from 'react'
import useSubcategory from '../hooks/useSubcategories'
import { Layout, Breadcrumbs, SideNavigation, QuestionLink, MissingArticleMessage } from '.'
import { NavLink } from 'react-router-dom'
import { generateBreadcrumbs, modelTypes, generateLinks, generateLink } from '../util/linkHelper'

const Sidebar = ({ subcategory, links }) => {
    const [siblings, setSiblings] = useState([])

    useEffect(() => {
        setSiblings(getSiblings())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subcategory, links])

    // Returns the parent subcategory where its slug matches the slug in the url
    const getActiveCategory = categories => categories.find(category =>
        category.active_slug.slug === links[modelTypes.category].slug
    )

    const getSiblings = () => {
        if (subcategory && subcategory.categories) {
            // Select the active category if one exists, otherwise just pick the first one from the list
            const activeCategory = getActiveCategory(subcategory.categories) || subcategory.categories[0]

            if (activeCategory) {
                return activeCategory.subcategories.map(({ id, title, active_slug }) => ({
                    id,
                    title,
                    link: generateLink(active_slug.slug, modelTypes.subcategory, links[modelTypes.category].slug),
                    isActive: active_slug.slug === links[modelTypes.subcategory].slug
                }))
            }
        }

        return []
    }

    return (
        <div>
            <NavLink className="page__small-heading" to={links[modelTypes.category].link}>
                <h3>{links[modelTypes.category].title}</h3>
            </NavLink>

            <SideNavigation links={siblings} />
        </div>
    )
}

const SubcategoryContent = ({ subcategory, links }) =>
    <div className="page__parent-content">
        <div className="page__breadcrumbs">
            <Breadcrumbs links={links} />
        </div>
        <div className="page__content">
            <div className="page__side-navigation">
                {links && links[modelTypes.category] && <Sidebar subcategory={subcategory} links={links} />}
            </div>

            <div className="page__center-content">
                {subcategory && <div className="page__content-block">
                    <h4 className="page__medium-heading">{subcategory.title}</h4>
                    {links && links[modelTypes.category] && subcategory.articles && subcategory.articles.map(({ title, id, active_slug }) =>
                        <div key={id} className="page__question-link">
                            <QuestionLink question={title} link={generateLink(active_slug.slug, modelTypes.article, links[modelTypes.category].slug, links[modelTypes.subcategory].slug)} />
                        </div>
                    )}
                    {subcategory.articles && subcategory.articles.length === 0 &&
                        <MissingArticleMessage/>
                    }
                </div>}
            </div>
        </div>
    </div>

const PageSubcategory = ({ match }) => {
    const { fetchSubcategory, detailSubcategory } = useSubcategory()
    const [breadcrumb, setBreadcrumb] = useState({})
    const [links, setLinks] = useState({})

    useEffect(() => {
        fetchSubcategory(match.params.subcategory_id, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match])

    useEffect(() => {
        const generatedBreadcrumb = generateBreadcrumbs(modelTypes.subcategory, detailSubcategory)
        const params = new URLSearchParams(document.location.search.substring(1));
        const matchingParams = {
            subcategoryId: match.params.subcategory_id,
            categoryId: params.get(modelTypes.category)
        }

        setBreadcrumb(generatedBreadcrumb)
        setLinks(generateLinks(generatedBreadcrumb, matchingParams))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailSubcategory])

    return (
        <Layout>
            <SubcategoryContent subcategory={detailSubcategory} breadcrumb={breadcrumb} links={links} />
        </Layout>
    )
}

export default PageSubcategory
