import { useDispatch, useSelector } from 'react-redux'

import {
    fetchSearch as fetchSearchAction,
    clearSearch as clearSearchAction
} from '../actions/globalActions'

const useSearch = () => {
    const dispatch = useDispatch()

    const searchResult = useSelector(state => state.global.searchResult)
    const isSearching = useSelector(state => state.global.isSearching)
    const isLoading = useSelector(state => state.global.isLoading)

    const fetchSearch = searchQuery => dispatch(fetchSearchAction(searchQuery))
    const clearResults = () => dispatch(clearSearchAction())

    return {
        searchResult,
        isSearching,
        isLoading,
        fetchSearch,
        clearResults
    }
}

export default useSearch
