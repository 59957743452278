import { useDispatch, useSelector } from 'react-redux'

import {
    fetchCategories as fetchCategoriesAction,
    fetchCategory as fetchCategoryAction,
} from '../actions/categoryActions'

const useCategories = () => {
    const dispatch = useDispatch()

    const allCategories = useSelector(state => state.categories.all)
    const detailCategory = useSelector(state => state.categories.detail)

    const fetchCategories = () => dispatch(fetchCategoriesAction())
    const fetchCategory = (categoryId, isSlug = false) => dispatch(fetchCategoryAction(categoryId, isSlug))


    return {
        fetchCategories,
        fetchCategory,
        allCategories,
        detailCategory
    }
}

export default useCategories
