import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import playIcon from '../assets/images/Play.svg'
import InfoIcon from '../assets/images/Info.svg'

export const supportedVideoHosts = {
    YouTube: 'YOUTUBE',
    Vimeo: 'VIMEO'
}

const unsupportedVideoHost = 'UNSUPPORTED'

const VideoPlayer = ({ videoUrl, videoDuration, thumbnailUrl, subtext }) => {
    const isYoutubeRegex = /^.+youtu(be|.be)?(\.com)?\/.+/
    const isVimeoRegex = /^.+vimeo(\.com)?\/\S+/

    const getYoutubeIdRegex = /^(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i
    const getVimeoIdRegex = /^.+(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/i

    let [shouldPlay, onPlay] = useState(false)
    let [embedUrl, setEmbedUrl] = useState(null)
    let [imageUrl, setImageUrl] = useState(null)

    useEffect(() => {
        const videoHost = getVideoHost(videoUrl)
        const videoId = getVideoId(videoHost, videoUrl)

        setEmbedUrl(getEmbedUrl(videoHost, videoId))
        setImageUrl(getImageUrl(videoHost, videoId))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoUrl, thumbnailUrl])

    /**
     * Detects the host for a given (video) url.
     * Returns either one of ["YOUTUBE", "VIMEO"] if this component supports embedding it,
     * else "UNSUPPORTED".
     * 
     * @param {string} url The url to detect the host of. Defaults to this components @var videoUrl
     * 
     * @returns {string} The host of the url.
     */
    const getVideoHost = (url = videoUrl) => {

        if (isYoutubeRegex.test(url)) {
            return supportedVideoHosts.YouTube
        }

        if (isVimeoRegex.test(url)) {
            return supportedVideoHosts.Vimeo
        }

        return unsupportedVideoHost
    }

    /**
     * Returns the video id for a youtube or vimeo url.
     * Requires a string describing the video host. Use @function getVideoHost to determine the host for a url.
     * 
     * @param {string} host The host for the video.
     * @param {string} url The url to determine the video id of.
     */
    const getVideoId = (host, url = videoUrl) => {
        switch (host) {
            case supportedVideoHosts.YouTube:
                return getYoutubeIdRegex.exec(url)[7]
            case supportedVideoHosts.Vimeo:
                return getVimeoIdRegex.exec(url)[5]
            default:
                return null
        }
    }

    /**
     * Generates an embeddable url for a video from a video id. use @function getVideoId to read the id from a url.
     * Use an `<iframe src={url} />` element to embed the video.
     * Requires a string describing the video host. Use @function getVideoHost to determine the host for a url.
     * 
     * @param {string} host The host for the video.
     * @param {string} id The id for the video to embed.
     */
    const getEmbedUrl = (host, id) => {
        switch (host) {
            case supportedVideoHosts.YouTube:
                return `https://www.youtube.com/embed/${id}?autoplay=1`
            case supportedVideoHosts.Vimeo:
                return `https://player.vimeo.com/video/${id}?autoplay=1`
            default: return
        }
    }

    /**
     * Generates a url for a cover image for a video from a video id. use @function getVideoId to read the id from a url.
     * Can generate a cover image for a YouTube video. Vimeo is currently not supported.
     * Will return `null` if it was unable to generate a url.
     * Requires a string describing the video host. Use @function getVideoHost to determine the host for a url.
     * 
     * @param {string} host The host for the video.
     * @param {string} id The id for the video to fetch a cover image for.
     */
    const getImageUrl = (host, id) => {
        if (thumbnailUrl) {
            return thumbnailUrl
        }

        switch (host) {
            case supportedVideoHosts.YouTube:
                return `https://img.youtube.com/vi/${id}/hqdefault.jpg`
            case supportedVideoHosts.Vimeo:
            default:
                return null
        }
    }

    const renderFeatureImage = () =>
        <div
            className="video-player__feature"
            style={imageUrl && {
                backgroundImage: `url('${imageUrl}')`
            }}
        >
            <div className="video-player__play-button" onClick={() => onPlay(true)} >
                <img src={playIcon} alt="play" />
            </div>

            {videoDuration &&
                <div className="video-player__duration-pill-wrapper">
                    <div className="video-player__duration-pill">
                        {videoDuration}
                    </div>
                </div>
            }
        </div>

    const renderVideo = () =>
        <iframe
            title={embedUrl}
            src={embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; fullscreen; picture-in-picture"
            allowFullScreen
        ></iframe>

    return (
        <div className="video-player__container">
            <div className="video-player__feature-wrapper">
                {!shouldPlay ? renderFeatureImage() : renderVideo()}
            </div>
            {subtext &&
                <div className="video-player__subtext-wrapper">
                    <div style={{backgroundImage: `url(${InfoIcon})`}} alt="info" className="video-player__subtext-icon" />
                    <div className="video-player__subtext">
                        {subtext}
                    </div>
                </div>
            }
        </div>
    )
}

VideoPlayer.propTypes = {
    videoUrl: PropTypes.string.isRequired,
    videoDuration: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    subtext: PropTypes.string,
}

export default VideoPlayer