import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { CategoryButton } from './'
import useCategories from '../hooks/useCategories'
import { mediaUrl } from '../util/imageHelper'
import fallbackIcon from '../assets/images/CategoryPlaceholder.svg'
import { generateLink, modelTypes } from '../util/linkHelper'

const CategoryButtonWrapper = ({ canShrink, max }) => {
    const { fetchCategories, allCategories } = useCategories()

    useEffect(() => {
        fetchCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="category-button-wrapper__flexbox">
            {allCategories.map(({ id, medias, title, subcategories, active_slug }) =>
                <div key={id} className="page__category">
                    <CategoryButton
                        icon={mediaUrl(medias[0], fallbackIcon)}
                        text={title}
                        max={max}
                        link={generateLink(active_slug.slug, modelTypes.category)}
                        subcategories={subcategories}
                        canShrink={canShrink}
                        categorySlug={active_slug.slug}
                    />
                </div>
            )}
        </div>
    )
}

CategoryButtonWrapper.defaultProps = {
    canShrink: false,
    max: 4,
}

CategoryButtonWrapper.propTypes = {
    canShrink: PropTypes.bool,
    max: PropTypes.number,
}

export default CategoryButtonWrapper
