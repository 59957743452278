import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { generateLink, modelTypes } from '../util/linkHelper'

const ClassNameContainer = 'category-button__container'
const ClassNameButton = 'category-button__button'
const ClassNameLinks = 'category-button__links-container'

const CategoryButton = ({ link, icon, text, max, canShrink, subcategories, categorySlug }) => {
    const getSlicedSubcategories = () => max > 0 ? subcategories.slice(0, max) : subcategories

    return (
        <div className={canShrink ? `${ClassNameContainer} ${ClassNameContainer}--shrink` : ClassNameContainer}>
            <Link className={canShrink ? `${ClassNameButton} ${ClassNameButton}--shrink` : ClassNameButton} to={link}>
                <img className="category-button__icon" src={icon} alt="Category Button" />
                <h4 className="category-button__header">{text}</h4>
            </Link>
            {subcategories &&
                <div className={canShrink ? `${ClassNameLinks} ${ClassNameLinks}--shrink` : ClassNameLinks}>
                    <div className="category-button__links">
                        {getSlicedSubcategories().map(({ id, title, active_slug }) =>
                            <Link key={id} className="category-button__link" to={generateLink(active_slug.slug, modelTypes.subcategory, categorySlug)}>{title}</Link>
                        )}
                    </div>
                    <Link className="category-button__main-link" to={link}>
                        <span className="category-button__arrow-flexbox">
                            <span className="category-button__arrow">➞</span>
                            <span className="category-button__text">Bekijk alles</span>
                        </span>
                    </Link>
                </div>
            }
        </div>
    )
}

CategoryButton.defaultProps = {
    max: 4,
}

CategoryButton.propTypes = {
    link: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    max: PropTypes.number,
    canShrink: PropTypes.bool,
    subcategories: PropTypes.arrayOf(PropTypes.object)

}

export default CategoryButton