import * as actions from '../actions'

const initialState = {
    all: []
}

function dossiers(state = initialState, action) {
    switch (action.type) {
        case actions.FETCH_DOSSIERS_SUCCESS:
            return {
                ...state,
                all: action.dossiers
            }
        case actions.FETCH_DOSSIERS:
            return {
                ...state,
                all: []
            }
        default:
            return state;
    }
}

export default dossiers
