const windowEnviroment = window.location.hostname === 'localhost' ? 'DEVELOPMENT' : 'STAGING'
const nodeEnvironment = process.env.REACT_APP_API_ENV || windowEnviroment
const apiEnvironment = process.env.REACT_APP_API_ENV || nodeEnvironment

const baseApiUrl = apiEnvironment === 'DEVELOPMENT' ?
    process.env.REACT_APP_DEVELOPMENT_API || 'http://localhost:8000' :
    process.env.REACT_APP_PRODUCTION_API || 'https://bieb-api.plinkr.nl'

const imageUrl = apiEnvironment === 'DEVELOPMENT' ?
    process.env.REACT_APP_DEVELOPMENT_IMAGE_ENDPOINT || 'http://localhost:8000' :
    process.env.REACT_APP_PRODUCTION_IMAGE_ENDPOINT ||'https://plinkr-bieb-cms.s3.eu-central-1.amazonaws.com'

// Server has to be rebooted for .env changes to take effect
const getNodeEnvironment = () => nodeEnvironment
const getApiEnvironment = () => apiEnvironment

const getBaseApiUrl = () => baseApiUrl
const getBaseImageUrl = () => imageUrl

export {
    getNodeEnvironment,
    getApiEnvironment,
    getBaseApiUrl,
    getBaseImageUrl
}