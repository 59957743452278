import React from 'react'
import { Header, CtaBlock, Footer } from '.'
import LogoFooter from '../assets/images/LogoFooter.svg'

const Layout = ({ headerIsLarge, children }) => {

    return (
        <div className="page__container">
            <div className="page-home__header">
                <Header imageLink="/" isLarge={headerIsLarge} />
            </div>
            {children}
            <div className="page__cta-block">
                <CtaBlock text="Het antwoord op je vraag niet kunnen vinden? Stuur het in en het netwerk van hulpverleners zoekt het voor je uit!" />
            </div>
            <Footer image={LogoFooter} links={{ 
                "Algemene voorwaarden": 'https://plinkr.nl/algemene-voorwaarden/', 
                "Privacy policy": 'https://plinkr.nl/privacy/', 
                "Ga naar plinkr.nl": 'https://plinkr.nl/' }}
            />
        </div>
    )
}


export default Layout
