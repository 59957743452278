import { useDispatch, useSelector } from 'react-redux'

import {
    fetchDossiers as fetchDossiersAction
} from '../actions/dossierActions'

const useDossiers = () => {
    const dispatch = useDispatch();

    const allDossiers = useSelector(state => state.dossiers.all)

    const fetchDossiers = () => dispatch(fetchDossiersAction())

    return {
        allDossiers,
        fetchDossiers,
    }
}

export default useDossiers
