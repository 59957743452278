import * as actions from '../actions'

const initialState = {
    all: [],
    detail: null,
}

function links(state = initialState, action) {
    switch (action.type) {
        case actions.FETCH_LINKS:
            return {
                ...state,
                all: action.links
            }
        case actions.FETCH_LINK:
            return {
                ...state,
                detail: action.link
            }
        default:
            return state;
    }
}

export default links