import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, Link } from 'react-router-dom'
import menu from '../assets/images/menu.svg'
import { Button, Searchbar, Menu, UserInputForm, StickyHeader } from '.'
import usePopups from '../hooks/usePopups';
import useMenu from '../hooks/useMenu'
import { Debt, Home, Library } from './icons'

import LogoHeader from '../assets/images/LogoHeader.svg'
import blue_magnifying_glass from '../assets/images/blue-magnifying-glass.svg'
import BackgroundLeft from '../assets/images/header/BackgroundLeft.svg'
import BackgroundCenter from '../assets/images/header/BackgroundCenter.svg'
import BackgroundRightMobile from '../assets/images/header/BackgroundRightMobile.svg'
import BackgroundRightDesktop from '../assets/images/header/BackgroundRightDesktop.svg'

const classNameContainer = 'header__container'
const classNameSearchbox = 'header__searchbox-container'
const Header = ({ imageLink, isLarge }) => {
    const { openPopup } = usePopups();
    const { openMenu, isMenuOpen } = useMenu();

    const linksArray = [
        { icon: <Home />, url: '/home', value: 'Home' },
        { icon: <Debt />, url: '/themas', value: "Thema's" },
        { icon: <Library />, url: '/dossiers', value: 'Dossiers' }
    ]

    const onClick = () => {
        const PopupContent = () => <UserInputForm />
        openPopup(PopupContent, 'Vraag Insturen')
    }

    const renderMobileMenu = () =>
        <Menu
            imageLink={imageLink}
            menuItems={linksArray}
            onClick = {onClick}
        />

    const getBackgroundClassName = type => `header__background-item header__background-${type}` + (isLarge ? '' : ` header__background-${type}--small`)

    return (
        <header className={isLarge ? `${classNameContainer} ${classNameContainer}--large` : classNameContainer}>
            <div className="header__background-container">
                <div className={getBackgroundClassName('left')}  style={{backgroundImage: `url(${BackgroundLeft})`}} />
                <div className={getBackgroundClassName('center')}   style={{backgroundImage: `url(${BackgroundCenter})`}} />
                <div className={getBackgroundClassName('right-mobile')}   style={{backgroundImage: `url(${BackgroundRightMobile})`}} />
                <div className={getBackgroundClassName('right-desktop')}  style={{backgroundImage: `url(${BackgroundRightDesktop})`}} />
            </div>
            <div className="header__content">
                <div className="header__top-content">
                    <Link className="header__image-link" to={imageLink}><img src={LogoHeader} className="header__logo" alt="logo" /></Link>
                    <div className="header__top-content-right">
                        <ul className="header__nav-items">
                            {linksArray.map(({ url, value }) =>
                                <li key={value} className="header__nav-item">
                                    <NavLink key={value} className="header__nav-link" activeClassName="header__nav-link--active" to={url}>{value}</NavLink>
                                </li>
                            )}
                            <Button link="/" text="Vraag Insturen" radius="tertiary" state="tertiary" onClick={onClick} />
                        </ul>
                        <div className="header__nav-button" onClick={openMenu}>
                            <img src={menu} alt="menu" />
                        </div>
                    </div>
                </div>
                <div className={isLarge ? `${classNameSearchbox} ${classNameSearchbox}--large` : classNameSearchbox}>
                    <div className="header__searchbox">
                        {isLarge &&
                            <h4 className="header__searchbox-text" >Waar ben je naar op zoek?</h4>
                        }
                        <div className="header__searchbox-searchbar">
                            <Searchbar decoration={blue_magnifying_glass} placeholder="Omschrijf je vraag ..." />
                        </div>
                    </div>
                </div>
            </div>
            {isMenuOpen && renderMobileMenu()}
            <StickyHeader 
                imageLink={imageLink} 
                isLarge={isLarge} 
            />
        </header>
    )
}

Header.propTypes = {
    imageLink: PropTypes.string,
    pageLinks: PropTypes.object,
    isLarge: PropTypes.bool,
}

export default Header
