// Global actions

export const REQUEST_START = 'REQUEST_START'
export const REQUEST_FINISH = 'REQUEST_FINISH'

export const POPUP_OPEN = 'POPUP_OPEN'
export const POPUP_DISMISS = 'POPUP_DISMISS'

export const MENU_OPEN = 'MENU_OPEN'
export const MENU_CLOSE = 'MENU_CLOSE'

export const FETCH_SEARCH = 'FETCH_SEARCH'
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS'
export const FETCH_SEARCH_FAILURE = 'FETCH_SEARCH_FAILURE'

export const CLEAR_SEARCH = 'CLEAR_SEARCH'

// Home actions

export const FETCH_FEATURED_ARTICLES = 'FETCH_FEATURED_ARTICLES'
export const FETCH_FEATURED_ARTICLES_SUCCESS = 'FETCH_FEATURED_ARTICLES_SUCCESS'
export const FETCH_FEATURED_ARTICLES_FAILURE = 'FETCH_FEATURED_ARTICLES_FAILURE'

// Category actions

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE'

export const FETCH_CATEGORY = 'FETCH_CATEGORY'
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS'
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE'

// Subcategory actions

export const FETCH_SUBCATEGORIES = 'FETCH_SUBCATEGORIES'
export const FETCH_SUBCATEGORIES_SUCCESS = 'FETCH_SUBCATEGORIES_SUCCESS'
export const FETCH_SUBCATEGORIES_FAILURE = 'FETCH_SUBCATEGORIES_FAILURE'

export const FETCH_SUBCATEGORY = 'FETCH_SUBCATEGORY'
export const FETCH_SUBCATEGORY_SUCCESS = 'FETCH_SUBCATEGORY_SUCCESS'
export const FETCH_SUBCATEGORY_FAILURE = 'FETCH_SUBCATEGORY_FAILURE'

// Dossier actions

export const FETCH_DOSSIERS = 'FETCH_DOSSIERS'
export const FETCH_DOSSIERS_SUCCESS = 'FETCH_DOSSIERS_SUCCESS'
export const FETCH_DOSSIERS_FAILURE = 'FETCH_DOSSIERS_FAILURE'

// Question link actions

export const FETCH_LINKS = 'FETCH_LINKS'
export const FETCH_LINKS_SUCCESS = 'FETCH_LINKS_SUCCESS'
export const FETCH_LINKS_FAILURE = 'FETCH_LINKS_FAILURE'

export const FETCH_LINK = 'FETCH_LINK'
export const FETCH_LINK_SUCCESS = 'FETCH_LINK_SUCCESS'
export const FETCH_LINK_FAILURE = 'FETCH_LINK_FAILURE'

// Article actions

export const FETCH_ARTICLES = 'FETCH_ARTICLES'
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS'
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE'

export const FETCH_ARTICLE = 'FETCH_ARTICLE'
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS'
export const FETCH_ARTICLE_FAILURE = 'FETCH_ARTICLE_FAILURE'

// Question form actions

export const POST_QUESTION = 'POST_QUESTION'
export const POST_QUESTION_SUCCESS = 'POST_QUESTION_SUCCESS'
export const POST_QUESTION_FAILURE = 'POST_QUESTION_FAILURE'

export const POST_QUESTION_RESET = 'POST_QUESTION_RESET'
