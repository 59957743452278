import _ from 'lodash'

const modelTypes = {
    article: 'vragen',
    subcategory: 'onderwerpen',
    category: 'themas',
}

/**
 * Recursive function. Generates a breadcrumb object containing a title and slug to the current object,
 * and a parents list containing breadcrumbs defining their parents.
 * Supports models with multiple parents.
 * 
 * Generates a breadcrumb object in the following format:
 * ```
 * {
 *    title,
 *    slug,
 *    type,
 *    parents: []
 * }
 * ```
 * 
 * The `parents` attribute is a list which may contain breadcrumbs describing the parents.
 * Expects the model hierarchy to be in the format article -> subcategory -> category.
 * Returns an empty object `{}` if either `model.title` or `type` are undefined or null.
 * 
 * @param {modelTypes} type The current type of model. Can be any one of `linkHelper.modelTypes`
 * @param {object} model The current model as returned by the API, containing at least a title and active_slug
 */
const generateBreadcrumbs = (type, { title, active_slug, subcategories, categories }) => {

    if (!title || !type) return {}

    const generateParentBreadcrumbs = (type, models) => models.map(model => generateBreadcrumbs(type, model))

    let breadcrumb = {
        title,
        slug: active_slug.slug,
        type,
    }

    // If this is an article and it has parent subcategories
    if (type === modelTypes.article && subcategories && subcategories.length > 0) {
        breadcrumb.parents = generateParentBreadcrumbs(modelTypes.subcategory, subcategories)
        breadcrumb.type = modelTypes.article
    }

    // If this is a subcategory and it has parent categories
    if (type === modelTypes.subcategory && categories && categories.length > 0) {
        breadcrumb.parents = generateParentBreadcrumbs(modelTypes.category, categories)
        breadcrumb.type = modelTypes.subcategory
    }

    return breadcrumb
}

/**
 * Generates an object, which contains the link and title for the current model, its parent and its parents parent.
 * If the model contains multiple parents, selects a parent based on the following priorities:
 * 
 * 1. The parent slug matches the slug in the `match` object, otherwise;
 * 2. The first parent slug in the list if defined, otherwise;
 * 3. Generates a link and title for the current slug
 * 
 * Returns an object in the following format
 * 
 * ```
 * {
 *    [modelType]: { title, link }
 * }
 * ```
 * 
 * Returns an empty object `{}` if `breadcrumb` or `breadcrumb.slug` is undefined or null.
 * 
 * @param {object} breadcrumb A breadcrumb object, as returned by `linkHelper.generateBreadcrumbs`
 * @param {match} match (optional) An object containing the slugs that this function should prefer
 * if multiple links are possible.
 */
const generateLinks = (breadcrumb, match = {}) => {
    let { articleId, subcategoryId, categoryId } = match

    if (!breadcrumb || !breadcrumb.slug) return {}

    let links = { [modelTypes.category]: null, [modelTypes.subcategory]: null, [modelTypes.article]: null }
    let currentUrl = { [modelTypes.category]: categoryId, [modelTypes.subcategory]: subcategoryId, [modelTypes.article]: articleId }

    // Retrieve the "preferred" breadcrumb from the list if it exists, otherwise returns the first, otherwise, generates a breadcrumb from the current slug
    const getPreferredBreadcrumb = (breadcrumbList = []) => {
        const preferredBreadcrumb = breadcrumbList.find((b) => b.slug === currentUrl[b.type])

        return preferredBreadcrumb || breadcrumbList[0] || { slug: breadcrumb.slug, title: _.upperFirst(_.lowerCase(breadcrumb.slug)) }
    }

    // Recursive function.
    const generateLinkFromBreadcrumb = (breadcrumbList) => {
        if (breadcrumbList && breadcrumbList[0]) {
            let queryParams = {}
            const preferredBreadcrumb = getPreferredBreadcrumb(breadcrumbList)
            const link = `/${preferredBreadcrumb.type}/${preferredBreadcrumb.slug}`

            if (preferredBreadcrumb.parents) {
                queryParams = generateLinkFromBreadcrumb(preferredBreadcrumb.parents)
            }

            links[preferredBreadcrumb.type] = {
                link: appendQueryParams(link, queryParams),
                title: preferredBreadcrumb.title,
                slug: preferredBreadcrumb.slug
            }

            queryParams[preferredBreadcrumb.type] = preferredBreadcrumb.slug

            return queryParams
        }
    }

    generateLinkFromBreadcrumb([breadcrumb])

    return links
}

/**
 * Appends the queryparams to the base url.
 * The base url can be a relative url (unlike the native `URLSearchParams.append()`)
 * Expects the params object to be in the following format
 * 
 * ```
 * {
 *    name: 'value'
 * }
 * ```
 * 
 * @param {string} base The base url string. Defaults to empty string `''`
 * @param {object} params The params to append. Defaults to empty object `{}`
 */
const appendQueryParams = (base = '', params = {}) => {
    let result = base;

    if (params && Object.entries(params).length > 0) {
        let stringifiedParams = []

        for (const param in params) {
            stringifiedParams.push(`${param}=${params[param]}`)
        }

        result = `${base}?${stringifiedParams.join('&')}`
    }

    return result
}

/**
 * Generates a link for the given slug & slug type
 * This function should be used to generate most links in this application
 * (As much as possible)
 * 
 * @param {*} slug The slug for this link
 * @param {*} type The slug type, should be one of `linkHelper.modelTypes`
 * @param {*} parentCategory (optional) The parent category.
 * Will be appended to the link as a URL query param
 * @param {*} parentSubcategory (optional) The parent subcategory.
 * Will be appended to the link as a URL query param
 */
const generateLink = (slug, type, parentCategory, parentSubcategory) => {
    let params = {}

    if (parentCategory) {
        params[modelTypes.category] = parentCategory
    }
    
    if (parentSubcategory) {
        params[modelTypes.subcategory] = parentSubcategory
    }

    return appendQueryParams(`/${type}/${slug}`, params)
}

export {
    generateBreadcrumbs,
    generateLinks,
    generateLink,
    appendQueryParams,
    modelTypes,
}