import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { Dossier } from './'
import useDossiers from '../hooks/useDossiers'
import { modelTypes, generateLink } from '../util/linkHelper'
import { getMediaByRole, mediaUrl } from '../util/imageHelper'

const MoreButton = () =>
    <div className="page-home__button">
        <Link
            to={'/dossiers'}
            className="button__container button__container--tertiary-radius button__container--primary-state"
        >
            Bekijk alles
        </Link>
    </div>

const DossierWrapper = ({ renderMoreButton }) => {
    const { fetchDossiers, allDossiers } = useDossiers()

    useEffect(() => {
        fetchDossiers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="dossier-wrapper__container">
            <div className="dossier-wrapper__flexbox">
                {allDossiers.map(({ id, title, dossier_subtitle: subtext, medias, active_slug }) => {
                    const smallBackground = getMediaByRole(medias, 'dossier_image_small')
                    const largeBackground = getMediaByRole(medias, 'dossier_image_wide')
                    const isLarge = !!largeBackground

                    return (
                        <div key={id} className="dossier">
                            <Dossier
                                isLarge={isLarge}
                                smallBackground={mediaUrl(smallBackground)}
                                largeBackground={mediaUrl(largeBackground)}
                                link={generateLink(active_slug.slug, modelTypes.article)}
                                text={title}
                                subtext={subtext}
                            />
                        </div>
                    )
                }
                )}
            </div>

            {renderMoreButton && <MoreButton />}
        </div>
    )
}

DossierWrapper.defaultProps = {
    renderMoreButton: true,
}

DossierWrapper.propTypes = {
    renderMoreButton: PropTypes.bool
}

export default DossierWrapper
