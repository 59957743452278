import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom';
import usePopups from '../hooks/usePopups';
import useMenu from '../hooks/useMenu';

const scrollWrapperId = 'scroll-wrapper'

const ScrollWrapper = ({ children, location: { pathname } }) => {
    const { hasPopup } = usePopups()
    const { isMenuOpen, closeMenu } = useMenu()
    const [ isScrollable, setScrollable ] = useState(true)

    useEffect(() => {
        const scrollWrapperNode = document.getElementById(scrollWrapperId)
        scrollWrapperNode && window.scrollTo(0, 0)

        isMenuOpen && closeMenu()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    useEffect(() => {
        setScrollable(!hasPopup || !isMenuOpen)
    }, [hasPopup, isMenuOpen])

    return (
        <div id={scrollWrapperId} className={isScrollable ? scrollWrapperId : `${scrollWrapperId} ${scrollWrapperId}--disable-scroll`}>
            {children || null}
        </div>
    )
}

export default withRouter(ScrollWrapper)
