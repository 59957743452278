import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'
import { Layout, Breadcrumbs, SideNavigation, SourceBlock, VideoPlayer } from '../components'
import { mediaUrl } from '../util/imageHelper'
import { generateBreadcrumbs, modelTypes, generateLinks, generateLink } from '../util/linkHelper'
import Categorisation from './Categorisation';
import useArticles from '../hooks/useArticles'
import ArticleImage from './ArticleImage'

export const articleTypes = {
    video: 'plinkr_video',
    image: 'image',
    text: 'text'
}

const VideoBody = ({ block: { content: { url, subtext, duration }, medias } }) =>
    <VideoPlayer videoUrl={url} subtext={_.get(subtext, 'nl')} videoDuration={duration} thumbnailUrl={mediaUrl(medias[0])} />

const TextBody = ({ block: { content: { html: body } } }) =>
    <div dangerouslySetInnerHTML={{ __html: body }} />

const ImageBody = ({ block: { medias } }) =>
    <ArticleImage url={mediaUrl(medias[0])} alt={medias.alt_text} />

const ArticleBlocks = ({ blocks }) => {
    return blocks.map((block) => {
        const { type, id } = block

        switch (type) {
            case articleTypes.video:
                return <VideoBody key={id} block={block} />
            case articleTypes.image:
                return <ImageBody key={id} block={block} />
            case articleTypes.text:
                return <TextBody key={id} block={block} />
            default:
                console.warn(`PageArticle: Unsupported block type ${type}`)
                return <div />
        }
    })
}

const Sidebar = ({ article, links }) => {
    const [siblings, setSiblings] = useState([])

    useEffect(() => {
        setSiblings(getSiblings())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article, links])

    // Returns the parent subcategory where its slug matches the slug in the url
    const getActiveSubcategory = subcategories => subcategories.find(subcategory =>
        subcategory.active_slug.slug === links[modelTypes.subcategory].slug
    )

    const getSiblings = () => {
        if (article && article.subcategories) {
            // Select the active subcategory if one exists, otherwise just pick the first one from the list
            const activeSubcategory = getActiveSubcategory(article.subcategories) || article.subcategories[0]

            if (activeSubcategory) {
                return activeSubcategory.articles.map(({ id, title, active_slug }) => ({
                    id,
                    title,
                    link: generateLink(active_slug.slug, modelTypes.article, links[modelTypes.category].slug, links[modelTypes.subcategory].slug),
                    isActive: active_slug.slug === links[modelTypes.article].slug
                }))
            }

            return []
        }
    }

    return (
        <div>
            <NavLink className="page__small-heading" to={links[modelTypes.subcategory].link}>
                <h3>{links[modelTypes.subcategory].title}</h3>
            </NavLink>

            <SideNavigation links={siblings} singleColumn />
        </div>
    )
}

const ArticleContent = ({ article, links }) => (
    <div className="page__parent-content">
        <div className="page page-article__breadcrumbs">
            {links && <Breadcrumbs links={links} />}
        </div>

        <div className="page__content page-article__content">
            <div className="page__side-navigation page-article__side-navigation">
                {links && links[modelTypes.subcategory] && <Sidebar article={article} links={links} />}
            </div>

            <div className="page-article__center-content">
                <h2 className="page__large-heading">{article.title}</h2>
                <div
                    id="page-article__body"
                    className="page-article__body"
                >
                    {article.blocks && <ArticleBlocks blocks={article.blocks} />}
                </div>

                <div className="page__source-block">
                    {article.sources && article.sources.map(({ id, title, url, medias }) => (
                        <SourceBlock key={id} text={title} link={url} subtext={url} icon={mediaUrl(medias[0])} />
                    ))}
                </div>

                {/*
                    <div className="page__tags">
                        {article.biebtags && <Tags tags={article.biebtags} />}
                    </div>
                */}

                <div className="page__categorisation">
                    <Categorisation
                        categories={links[modelTypes.category] ? [links[modelTypes.category]] : []}
                        subcategories={links[modelTypes.subcategory] ? [links[modelTypes.subcategory]] : []}
                    />
                </div>
            </div>
        </div>
    </div>
)

const PageArticle = ({ match }) => {
    const { fetchArticle, detailArticle } = useArticles()
    const [breadcrumb, setBreadcrumb] = useState({})
    const [links, setLinks] = useState({})

    useEffect(() => {
        fetchArticle(match.params.article_id, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match])

    useEffect(() => {
        const generatedBreadcrumb = generateBreadcrumbs(modelTypes.article, detailArticle)
        const params = new URLSearchParams(document.location.search.substring(1));
        const matchingParams = {
            articleId: match.params.article_id,
            subcategoryId: params.get(modelTypes.subcategory),
            categoryId: params.get(modelTypes.category)
        }

        setBreadcrumb(generatedBreadcrumb)
        setLinks(generateLinks(generatedBreadcrumb, matchingParams))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailArticle])

    return (
        <Layout>
            <ArticleContent article={detailArticle} breadcrumb={breadcrumb} links={links} />
        </Layout>
    )
}

export default PageArticle
